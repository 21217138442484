import { useTranslation } from 'next-i18next';
import { BrandIndicator, Icons, Text } from '@imus/services-ui';
import type { SocialProviders } from '../types';
import styled from '@emotion/styled';
import { BioLink } from './BioCard';
import {
  ExternalLinkIcon,
  LinkIcon,
  NotificationBellIcon,
  ShareIcon,
  StarIcon,
  XIcon,
} from '@imus/base-ui';
import { css } from '@emotion/react';

const getSocialIconColor = (name: SocialProviders): string => {
  switch (name) {
    case 'instagram':
      return 'linear-gradient(225deg, #7638FA 0%, #D300C5 25.52%, #FF0069 51.56%, #FF7A00 76.04%, #FFD600 100%);';
    case 'twitter':
      return '#fff';
    case 'youtube':
      return '#FF0000';
    case 'facebook':
      return '#1877F2';
    case 'bandcamp':
      return '#000000';
    case 'soundcloud':
      return '#F26F23';
    default:
      return '#222';
  }
};

const getSocialUrl = (name: SocialProviders, id: string) => {
  switch (name) {
    case 'instagram':
      return 'https://instagram.com/' + id;
    case 'twitter':
      return 'https://x.com/' + id;
    // case 'deezer':
    //   return 'https://deezer.com/' + id;
    // case 'spotify':
    //   return 'https://spotify.com/' + id;
    // case 'apple_music':
    //   return 'https://music.apple.com/us/artist/' + id;
    case 'bandcamp':
      return `https://${id}.bandcamp.com/`;
    case 'youtube':
      return 'https://youtube.com/' + (id.includes('/') ? id : `@${id}`);
    case 'facebook':
      return 'https://facebook.com/' + id;
    case 'soundcloud':
      return 'https://soundcloud.com/' + id;
    default: {
      const exhustiveCheck: never = name;
      throw new Error(`unhandled social media type ${exhustiveCheck}`);
    }
  }
};

export const socialBackgroundVars = css`
  --Custom-Link-Style-4-Background: radial-gradient(
      243.37% 141.42% at 0% 100%,
      rgba(0, 78, 100, 0) 0%,
      #004e64 80.51%
    ),
    #73fc8a;
  --Custom-Link-Style-1-Background: #e8eddf;
  --Custom-Link-Style-2-Background: #004e64;
  --Custom-Link-Style-3-Background: radial-gradient(
      141.42% 141.42% at 0% 100%,
      rgba(0, 78, 100, 0.3) 0%,
      rgba(0, 78, 100, 0) 100%
    ),
    radial-gradient(141.42% 141.42% at 100% 100%, #fdca40 0%, #e8eddf 71.5%);
`;

export const CustomIconBase = ({
  iconUrl,
  iconBackground,
  ...props
}: {
  iconUrl?: string;
  iconBackground?: string;
  className?: string;
  style?: React.CSSProperties;
}) => {
  switch (iconUrl) {
    case 'link':
      return <LinkIcon {...props} />;
    case 'star':
      return <StarIcon {...props} />;
    case 'share':
      return <ShareIcon {...props} />;
    case 'bell':
      return <NotificationBellIcon {...props} />;
    default:
      return <LinkIcon {...props} />;
  }
};

export const CustomIcon = styled(CustomIconBase)`
  ${socialBackgroundVars};
  background: ${({ iconBackground }) =>
    iconBackground
      ? `var(--Custom-Link-Style-${iconBackground}-Background)`
      : 'var(--Custom-Link-Style-1-Background)'};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  --on-surface: #000;
  path {
    stroke: #000 !important;
  }
`;

export const SocialIcon = styled(Icons.Icon) <{
  background: string;
  fill: string;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;

  background: ${({ background }) => background};
  path {
    fill: ${({ fill }) => fill} !important;
  }
`;
const XIconStyled = styled(XIcon)`
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 8px;
  --on-surface: #fff;
  background: #000;

  path {
    fill: #fff !important;
  }
`;

const IconCard = ({
  label,
  iconUrl,
  iconBackground,
  url,
  icon,
  iconColor,
  iconFill = '#fff',
}: {
  iconFill?: string;
  iconUrl?: string;
  label: string;
  sublabel?: string;
  urlText: string;
  url: string;
  icon: string;
  iconColor: string;
  iconBackground?: string;
}) => (
  <BioLink onClick={() => window.open(url, '_blank') || undefined}>
    {iconUrl || iconBackground ? (
      <CustomIcon iconUrl={iconUrl} iconBackground={iconBackground} />
    ) : icon === 'twitter' ? (
      <XIconStyled />
    ) : (
      <SocialIcon
        fill={iconFill}
        background={iconColor}
        icon={icon}
      ></SocialIcon>
    )}

    <div>
      <Text.ContentM>{label}</Text.ContentM>
    </div>
    <ExternalLinkIcon
      style={{ marginLeft: 'auto', width: '24px', height: '24px' }}
    />
  </BioLink>
);

export const CustomLinkCard = ({
  name,
  url,
  iconColor,
  iconUrl,
}: {
  name: string;
  url: string;
  iconColor?: string;
  iconUrl?: string;
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ position: 'relative' }}>
      <IconCard
        iconUrl={iconUrl}
        iconBackground={iconColor}
        iconColor={'rgba(0, 0, 0, 0.9)'}
        icon={'link'}
        label={name}
        url={url}
        urlText={t('visit')}
      />
      <span style={{ position: 'absolute', top: '4px', right: '4px' }}>
        <BrandIndicator />
      </span>
    </div>
  );
};

const atSymbolSupported: SocialProviders[] = [
  'twitter',
  'instagram',
  'youtube',
];

export const getSocialHandlerPrefix = (
  id: SocialProviders,
  v: string | undefined
) => {
  if (id === 'youtube' && v) {
    if (v.includes('/')) return '';
    return '@';
  }
  return atSymbolSupported.indexOf(id) >= 0 ? '@' : '';
};

const formatSocialId = (id: SocialProviders, v: string | undefined) => {
  if (id === 'youtube' && v && v.includes('channel/')) return '';

  return `${getSocialHandlerPrefix(id, v)}${v}`;
};

export const SocialCard = ({
  name,
  id,
}: {
  name: SocialProviders;
  id: string;
}) => {
  const { t } = useTranslation();

  return (
    <IconCard
      iconColor={getSocialIconColor(name)}
      icon={name}
      label={t(name)}
      sublabel={formatSocialId(name, id)}
      url={getSocialUrl(name, id)}
      urlText={t('follow')}
    />
  );
};
